import * as _isArray2 from "lodash/isArray";
var _isArray = "default" in _isArray2 ? _isArray2.default : _isArray2;
import { dew as _toArrayDew } from "../parsing/toArray";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var isArray = _isArray;
  var toArray = _toArrayDew();

  /**
   * Serialize an array of arrays into a string
   * @param {string[] | Array.<Array.<string>>} array - An array of arrays.
   *                          If the first element is not an array the argument is wrapped in an array.
   * @returns {string} A string representation of the arrays.
   */
  function encodeDoubleArray(array) {
    array = toArray(array);
    if (!isArray(array[0])) {
      array = [array];
    }
    return array.map(function (e) {
      return toArray(e).join(",");
    }).join("|");
  }
  exports = encodeDoubleArray;
  return exports;
}