import _buffer from "buffer";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var Buffer = _buffer.Buffer;
  function base64Encode(input) {
    if (!(input instanceof Buffer)) {
      input = Buffer.from(String(input), "binary");
    }
    return input.toString("base64");
  }
  exports.base64Encode = base64Encode;
  return exports;
}