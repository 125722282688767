import { dew as _searchDew } from "./search";
import { dew as _apiDew } from "./api";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const Search = _searchDew();
  const api = _apiDew();
  const SearchFolders = class SearchFolders extends Search {
    constructor() {
      super();
    }
    static instance() {
      return new SearchFolders();
    }
    execute(options, callback) {
      if (callback === null) {
        callback = options;
      }
      options = options || {};
      return api.search_folders(this.to_query(), options, callback);
    }
  };
  exports = SearchFolders;
  return exports;
}