import { dew as _apiDew } from "../api";
import { dew as _utilsDew } from "../utils";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const api = _apiDew();
  const v1_adapters = _utilsDew().v1_adapters;
  v1_adapters(exports, api, {
    ping: 0,
    usage: 0,
    resource_types: 0,
    resources: 0,
    resources_by_tag: 1,
    resources_by_context: 2,
    resources_by_moderation: 2,
    resource_by_asset_id: 1,
    resources_by_asset_ids: 1,
    resources_by_ids: 1,
    resources_by_asset_folder: 1,
    resource: 1,
    restore: 1,
    update: 1,
    delete_resources: 1,
    delete_resources_by_prefix: 1,
    delete_resources_by_tag: 1,
    delete_all_resources: 0,
    delete_derived_resources: 1,
    tags: 0,
    transformations: 0,
    transformation: 1,
    delete_transformation: 1,
    update_transformation: 2,
    create_transformation: 2,
    upload_presets: 0,
    upload_preset: 1,
    delete_upload_preset: 1,
    update_upload_preset: 1,
    create_upload_preset: 0,
    root_folders: 0,
    sub_folders: 1,
    delete_folder: 1,
    create_folder: 1,
    upload_mappings: 0,
    upload_mapping: 1,
    delete_upload_mapping: 1,
    update_upload_mapping: 1,
    create_upload_mapping: 1,
    list_streaming_profiles: 0,
    get_streaming_profile: 1,
    delete_streaming_profile: 1,
    update_streaming_profile: 1,
    create_streaming_profile: 1,
    publish_by_ids: 1,
    publish_by_tag: 1,
    publish_by_prefix: 1,
    update_resources_access_mode_by_prefix: 2,
    update_resources_access_mode_by_tag: 2,
    update_resources_access_mode_by_ids: 2,
    search: 1,
    search_folders: 1,
    delete_derived_by_transformation: 2,
    add_metadata_field: 1,
    list_metadata_fields: 1,
    delete_metadata_field: 1,
    metadata_field_by_field_id: 1,
    update_metadata_field: 2,
    update_metadata_field_datasource: 2,
    delete_datasource_entries: 2,
    restore_metadata_field_datasource: 2,
    order_metadata_field_datasource: 3,
    reorder_metadata_fields: 2
  });
  return exports;
}