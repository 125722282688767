var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;
      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);
          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }
      return _arr;
    }
    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  /**
   * Helper function. Gets or populates srcset breakpoints using provided parameters
   * Either the breakpoints or min_width, max_width, max_images must be provided.
   *
   * @module utils
   * @private
   * @param {srcset} srcset Options with either `breakpoints` or `min_width`, `max_width`, and `max_images`
   *
   * @return {number[]} Array of breakpoints
   *
   */
  function generateBreakpoints(srcset) {
    var breakpoints = srcset.breakpoints || [];
    if (breakpoints.length) {
      return breakpoints;
    }
    var _map = [srcset.min_width, srcset.max_width, srcset.max_images].map(Number),
      _map2 = _slicedToArray(_map, 3),
      min_width = _map2[0],
      max_width = _map2[1],
      max_images = _map2[2];
    if ([min_width, max_width, max_images].some(Number.isNaN)) {
      throw "Either (min_width, max_width, max_images) " + "or breakpoints must be provided to the image srcset attribute";
    }
    if (min_width > max_width) {
      throw "min_width must be less than max_width";
    }
    if (max_images <= 0) {
      throw "max_images must be a positive integer";
    } else if (max_images === 1) {
      min_width = max_width;
    }
    var stepSize = Math.ceil((max_width - min_width) / Math.max(max_images - 1, 1));
    for (var current = min_width; current < max_width; current += stepSize) {
      breakpoints.push(current);
    }
    breakpoints.push(max_width);
    return breakpoints;
  }
  exports = generateBreakpoints;
  return exports;
}