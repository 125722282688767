var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  /**
   * Helper function. Gets or populates srcset breakpoints using provided parameters
   * Either the breakpoints or min_width, max_width, max_images must be provided.
   *
   * @module utils
   * @private
   * @param {srcset} srcset Options with either `breakpoints` or `min_width`, `max_width`, and `max_images`
   *
   * @return {number[]} Array of breakpoints
   *
   */
  function generateBreakpoints(srcset) {
    let breakpoints = srcset.breakpoints || [];
    if (breakpoints.length) {
      return breakpoints;
    }
    let [min_width, max_width, max_images] = [srcset.min_width, srcset.max_width, srcset.max_images].map(Number);
    if ([min_width, max_width, max_images].some(Number.isNaN)) {
      throw "Either (min_width, max_width, max_images) " + "or breakpoints must be provided to the image srcset attribute";
    }
    if (min_width > max_width) {
      throw "min_width must be less than max_width";
    }
    if (max_images <= 0) {
      throw "max_images must be a positive integer";
    } else if (max_images === 1) {
      min_width = max_width;
    }
    let stepSize = Math.ceil((max_width - min_width) / Math.max(max_images - 1, 1));
    for (let current = min_width; current < max_width; current += stepSize) {
      breakpoints.push(current);
    }
    breakpoints.push(max_width);
    return breakpoints;
  }
  exports = generateBreakpoints;
  return exports;
}