import * as _isString2 from "lodash/isString";
var _isString = "default" in _isString2 ? _isString2.default : _isString2;
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var isString = _isString;

  /**
   * Checks whether a given url or path is a local file
   * @param {string} url the url or path to the file
   * @returns {boolean} true if the given url is a remote location or data
   */
  function isRemoteUrl(url) {
    return isString(url) && /^ftp:|^https?:|^gs:|^s3:|^data:([\w-.]+\/[\w-.]+(\+[\w-.]+)?)?(;[\w-.]+=[\w-.]+)*;base64,([a-zA-Z0-9\/+\n=]+)$/.test(url);
  }
  exports = isRemoteUrl;
  return exports;
}