import * as _fs2 from "fs";
var _fs = "default" in _fs2 ? _fs2.default : _fs2;
import * as _path2 from "path";
var _path = "default" in _path2 ? _path2.default : _path2;
import _process from "process";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var process = _process;
  var fs = _fs;
  var path = _path;
  var sdkCode = "M"; // Constant per SDK

  /**
   * @description Gets the relevant versions of the SDK(package version, node version and sdkCode)
   * @param {'default' | 'x.y.z' | 'x.y' | string} useSDKVersion Default uses package.json version
   * @param {'default' | 'x.y.z' | 'x.y' | string} useNodeVersion Default uses process.versions.node
   * @return {{sdkSemver:string, techVersion:string, sdkCode:string}} A map of relevant versions and codes
   */
  function getSDKVersions() {
    var useSDKVersion = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "default";
    var useNodeVersion = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "default";
    var pkgJSONFile = fs.readFileSync(path.join(new URL(import.meta.url.slice(0, import.meta.url.lastIndexOf("/"))).pathname, "../../../../package.json"), "utf-8");

    // allow to pass a custom SDKVersion
    var sdkSemver = useSDKVersion === "default" ? JSON.parse(pkgJSONFile).version : useSDKVersion;

    // allow to pass a custom techVersion (Node version)
    var techVersion = useNodeVersion === "default" ? process.versions.node : useNodeVersion;
    return {
      sdkSemver,
      techVersion,
      sdkCode
    };
  }
  exports = getSDKVersions;
  return exports;
}