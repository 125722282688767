import * as _coreJs2 from "core-js";
var _coreJs = "default" in _coreJs2 ? _coreJs2.default : _coreJs2;
import { dew as _cloudinaryDew } from "./lib-es5/cloudinary";
import { dew as _cloudinary2Dew } from "./lib/cloudinary";
import _process from "process";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var process = _process;
  if (Number(process.versions.node.split(".")[0]) < 8) {
    console.warn("DEPRECATION NOTICE - Node 6 has been scheduled for removal from the Cloudinary SDK");
    // required polyfills for native ES6/7 functions (such as String.prototype.padStart)
    _coreJs;
    exports = _cloudinaryDew();
  } else {
    exports = _cloudinary2Dew();
  }
  return exports;
}