import { dew as _configDew } from "../config";
import { dew as _utilsDew } from "../utils";
import { dew as _ensureOptionDew } from "../utils/ensureOption";
import { dew as _execute_requestDew } from "./execute_request";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // eslint-disable-next-line import/order
  var config = _configDew();
  var utils = _utilsDew();
  var ensureOption = _ensureOptionDew().defaults(config());
  var execute_request = _execute_requestDew();
  var ensurePresenceOf = utils.ensurePresenceOf;
  function call_account_api(method, uri, params, callback, options) {
    ensurePresenceOf({
      method,
      uri
    });
    var cloudinary = ensureOption(options, "upload_prefix", "https://api.cloudinary.com");
    var account_id = ensureOption(options, "account_id");
    var api_url = [cloudinary, "v1_1", "provisioning", "accounts", account_id].concat(uri).join("/");
    var auth = {
      key: ensureOption(options, "provisioning_api_key"),
      secret: ensureOption(options, "provisioning_api_secret")
    };
    return execute_request(method, params, auth, api_url, callback, options);
  }
  exports = call_account_api;
  return exports;
}