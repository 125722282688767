import { dew as _base64EncodeDew } from "./base64Encode";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _require = _base64EncodeDew(),
    base64Encode = _require.base64Encode;
  function base64EncodeURL(sourceUrl) {
    try {
      sourceUrl = decodeURI(sourceUrl);
    } catch (error) {
      // ignore errors
    }
    sourceUrl = encodeURI(sourceUrl);
    return base64Encode(sourceUrl).replace(/\+/g, "-") // Convert '+' to '-'
    .replace(/\//g, "_") // Convert '/' to '_'
    .replace(/=+$/, ""); // Remove ending '=';
  }

  exports.base64EncodeURL = base64EncodeURL;
  return exports;
}