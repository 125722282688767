import { dew as _cloudinaryDew } from "../cloudinary";
import { dew as _apiDew } from "./api";
import { dew as _uploaderDew } from "./uploader";
import { dew as _searchDew } from "./search";
import { dew as _search_foldersDew } from "./search_folders";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  var v1 = _cloudinaryDew();
  var api = _apiDew();
  var uploader = _uploaderDew();
  var search = _searchDew();
  var search_folders = _search_foldersDew();
  var v2 = _extends({}, v1, {
    api,
    uploader,
    search,
    search_folders
  });
  exports = v2;
  return exports;
}