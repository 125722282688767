import * as _isArray2 from "lodash/isArray";
var _isArray = "default" in _isArray2 ? _isArray2.default : _isArray2;
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var isArray = _isArray;

  /**
   * @desc Turns arguments that aren't arrays into arrays
   * @param arg
   * @returns { any | any[] }
   */
  function toArray(arg) {
    switch (true) {
      case arg == null:
        return [];
      case isArray(arg):
        return arg;
      default:
        return [arg];
    }
  }
  exports = toArray;
  return exports;
}