import { dew as _cloudinaryDew } from "../cloudinary";
import { dew as _apiDew } from "./api";
import { dew as _uploaderDew } from "./uploader";
import { dew as _searchDew } from "./search";
import { dew as _search_foldersDew } from "./search_folders";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const v1 = _cloudinaryDew();
  const api = _apiDew();
  const uploader = _uploaderDew();
  const search = _searchDew();
  const search_folders = _search_foldersDew();
  const v2 = {
    ...v1,
    api,
    uploader,
    search,
    search_folders
  };
  exports = v2;
  return exports;
}