import { dew as _configDew } from "../config";
import { dew as _utilsDew } from "../utils";
import { dew as _ensureOptionDew } from "../utils/ensureOption";
import { dew as _execute_requestDew } from "./execute_request";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // eslint-disable-next-line import/order
  const config = _configDew();
  const utils = _utilsDew();
  const ensureOption = _ensureOptionDew().defaults(config());
  const execute_request = _execute_requestDew();
  const {
    ensurePresenceOf
  } = utils;
  function call_api(method, uri, params, callback, options) {
    ensurePresenceOf({
      method,
      uri
    });
    const api_url = utils.base_api_url(uri, options);
    let auth = {};
    if (options.oauth_token || config().oauth_token) {
      auth = {
        oauth_token: ensureOption(options, "oauth_token")
      };
    } else {
      auth = {
        key: ensureOption(options, "api_key"),
        secret: ensureOption(options, "api_secret")
      };
    }
    return execute_request(method, params, auth, api_url, callback, options);
  }
  exports = call_api;
  return exports;
}